// Angular
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";

// RXJS
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessPageComponent implements OnInit, OnDestroy {
  // full background image
  image: string;
  // error code, some error types template has it
  code: string;
  // error title
  title: string;
  // error subtitle, some error types template has it
  subtitle: string;
  // error descriptions
  desc: string;
  // return back button title
  return: any;

  private sub: Subscription;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.code = params.code;
      switch (this.code) {
        case '200':
          this.code = '200';
          this.title = 'Download Completed!';
          this.subtitle = 'Ride Receipt has been downloaded successfully. You can find the receipt pdf in your downloads folder.';
          break;
        default:
          this.code = '404';
          this.title = 'Page Not Found...';
          this.subtitle = 'Hmm... Sorry we can\'t seem to find the page you\'re looking for. There may be a misspelling in the URL entered or the page you are looking for may no longer exist.';
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  goBack() {
    this.location.back();
  }
}
