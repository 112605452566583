import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPermissionsModule } from 'ngx-permissions';

import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbCarouselModule,
  NgbProgressbarModule,
  NgbDatepickerModule,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";

// google maps
import { AgmCoreModule } from "@agm/core";

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ErrorPageComponent } from './general/error-page/error-page.component';
import { SplashScreenComponent } from './general/splash-screen/splash-screen.component';
import { AlertComponent } from './crud/alert/alert.component';
import { ConfirmationEntityComponent } from './crud/confirmation-entity/confirmation-entity.component';

// Environment Variables
import { environment } from '../../../environments/environment';
import { SuccessPageComponent } from './general/success-page/success-page.component';


@NgModule({
  declarations: [
  	ErrorPageComponent,
  	SplashScreenComponent,
  	AlertComponent,
  	ConfirmationEntityComponent,
   SuccessPageComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPaginationModule,
    // ClickOutsideModule,
    RouterModule,
    MatDialogModule,
    MatTooltipModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    NgxPermissionsModule.forChild(),
  ],
  providers: [
    // {
    //   provide: DROPZONE_CONFIG,
    //   useValue: config,
    // }
  ],
  exports: [
    SplashScreenComponent,
    AlertComponent,
    ConfirmationEntityComponent,
    Ng2SearchPipeModule,
  ],
  entryComponents: [
    ConfirmationEntityComponent,
  ],
})
export class PartialsModule {}
